<template>
  <div>
    <div class="flexBox">
      <!-- 포인트 -->
      <div class="flexInner">
        <div class="listInfo">
          <div class="title">나의 포인트</div>
          <div class="sort">
            <router-link to="/mypage/point/history/1" class="viewmore">더보기</router-link>
          </div>
        </div>
        <div v-if="!isLogin" class="innerBox noData">로그인 후 사용하세요</div>
        <!--  포인트 컨텐츠 -->
        <div v-else-if="point" class="innerBox myPoint">
          <p class="boxTitle"><span class="ess">{{ user.name }}</span>님의 포인트는</p>
          <p class="point"><strong>{{comma(point.point)}}</strong>점</p>
          <div class="barcode"> <!-- 바코드로 쓸만한 데이터가 없던데 포인트 식별자가 있는지? -->
            <barcode
              :value="user.idx"
              :margin="0"
              :font="'Pretendard'"
              :fontSize="20"
              :textMargin="3" />
          </div>
          <div class="btns">
            <router-link to="/mypage/point/coupon/1" class="btn" data-btn="coupon">쿠폰함<span class="cnt">{{comma(point.coupon)}}개</span></router-link>
            <router-link to="/mypage/point/reservation/1" class="btn" data-btn="ticket">예매권<span class="cnt">{{comma(point.advance_ticket)}}개</span></router-link>
          </div>
        </div>
        <div v-else class="innerBox noData">데이터가 없습니다</div>
        <!--  //포인트 컨텐츠 -->
      </div>
      <!-- //포인트 -->
      <!-- 시즌권 (나의 티켓으로 임시 변경) -->
      <div class="flexInner">
        <div class="listInfo">
          <div class="title">나의 시즌티켓</div>
          <div class="sort">
            <router-link to="/mypage/pay/entrySeason/1" class="viewmore">더보기</router-link>
          </div>
        </div>
        <div v-if="!isLogin" class="innerBox noData">로그인 후 사용하세요</div>
        <!-- 시즌권 컨텐츠 -->
        <div v-else-if="season.length > 0" class="innerBox mySeason">
          <p class="boxTitle">{{ season[0].season }} 시즌티켓(일반석)</p>
          <div class="tickets">
            <vueper-slides
              :slide-ratio="223/366"
              :infinite="false"
              :duration="5000"
              :arrows="false"
              :gap="3"
              class="no-shadow">
              <vueper-slide
                v-for="(s, i) in season"
                :key="i">
                <template #content>
                  <div class="seasonTicket">
                    <div class="ticketContent">
                      <p class="buyer">{{s.buyer}}님</p>
                      <p class="name">{{s.season}} 시즌티켓({{s.ticket_place}})</p>
                      <p class="num">{{s.number}}</p>
                      <div class="barcode">
                        <barcode
                          :value="s.number"
                          :margin="0"
                          :displayValue="false" />
                      </div>
                    </div>
                  </div>
                </template>
              </vueper-slide>
            </vueper-slides>
          </div>
          <div class="btns">
            <router-link to="/mypage/pay/entrySeason/1" class="btn" data-btn="entry">시즌티켓 입장내역 조회</router-link>
          </div>
        </div>
        <div v-else class="innerBox noData">데이터가 없습니다</div>
        <!-- 나의 티켓으로 임시 변경(준비중입니다) -->
<!--        <div class="innerBox noData">준비중입니다</div>-->
        <!-- //시즌권 컨텐츠 -->
      </div>
      <!-- //시즌권 -->
    </div>
    <div class="flexBox">
      <!-- 티켓팅 -->
      <div class="flexInner">
        <div class="listInfo">
          <div class="title">나의 티켓팅</div>
          <div class="sort">
            <router-link to="/mypage/pay/ticket/1" class="viewmore">더보기</router-link>
          </div>
        </div>
        <div v-if="!isLogin" class="innerBox noData">로그인 후 사용하세요</div>
        <!-- 티켓팅 컨텐츠 -->
<!--        <div v-else-if="reserveData.list.length > 0" class="innerBox myTicket">-->
          <div v-else class="innerBox myTicket">
          <div class="counts">
            <div class="count left" data-cnt="game">
              <div class="title">예매 경기</div>
              <div class="value"><strong>{{ reserveData.cnt }}</strong>경기</div>
            </div>
            <div class="count center" data-cnt="reservation">
              <div class="title">예매 매수</div>
              <div class="value"><strong>{{ reserveData.total }}</strong>매</div>
            </div>
            <div class="count right" data-cnt="rate">
              <div class="title">예매 승률</div>
              <div class="value"><strong>{{ odds }}</strong>%</div>
            </div>
          </div>
          <div class="list"
               :class="{ 'has-border': reserveData.list.length > 0 }"
               v-if="reserveData.list.length > 0" >
            <div class="row" v-for="row in reserveData.list">
              <span class="cell hiddenMobile">{{ row.reserveNo }}</span>
              <span class="cell fix">
                VS
                <img :src="row.awayTeamCode" class="emblem hiddenMobile" alt="">
                <strong>{{ row.vs }}<i class="hiddenMobile"></i></strong>
              </span>
              <span class="cell fix">{{ row.gameDate }}</span>
              <span class="cell ess">{{ row.reserveCount }}매</span>
            </div>
          </div>
            <div v-else class="list noData_list">데이터가 없습니다</div>
        </div>
<!--        <div v-else class="innerBox noData">데이터가 없습니다</div>-->
        <!-- //티켓팅 컨텐츠 -->
      </div>
      <!-- //티켓팅 -->
      <!-- 구매상품 -->
      <div class="flexInner">
        <div class="listInfo">
          <div class="title">나의 구매상품</div>
          <div class="sort">
            <router-link to="/mypage/pay/goods/1" class="viewmore">더보기</router-link>
          </div>
        </div>
        <div v-if="!isLogin" class="innerBox noData">로그인 후 사용하세요</div>
        <!-- 구매상품 컨텐츠 -->
        <div v-else-if="md.total > 0" class="innerBox myPay">
          <div class="counts">
            <div class="count left" data-cnt="count">
              <div class="title">구매횟수</div>
              <div class="value"><strong>{{ md.order }}</strong>회</div>
            </div>
            <div class="count center" data-cnt="pay">
              <div class="title">구매건수</div>
              <div class="value"><strong>{{ md.total }}</strong>건</div>
            </div>
            <!-- // 장바구니 2차 오픈
            <div class="count right" data-cnt="cart">
              <div class="title">장바구니</div>
              <div class="value"><strong>??</strong>건</div>
            </div>
            -->
          </div>
          <div class="list table">
            <div class="row" v-for="(value, name, index) in md.list" v-if="index < 3">
              <!--<span class="cell thumb hiddenMobile"><img src="http://www.jbfcmall.com/web/product/big/202202/caa488f2d06453ea5adccbb3c0625274.jpg" alt="" /></span>-->
              <span class="cell">{{ name }}</span>
              <a href="https://shop.hyundai-motorsfc.com/" target="_blank" class="cell fix">
                <strong>{{ value[0].product_name }}</strong>
                <strong v-if="value.length > 1"> 외 {{ value.length-1 }}건</strong>
              </a>
            </div>
          </div>
        </div>
        <div v-else class="innerBox noData">데이터가 없습니다</div>
        <!-- //구매상품 컨텐츠 -->
      </div>
      <!-- //구매상품 -->
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import VueBarcode from 'vue-barcode'
import regExp from '@/common/regExp'
import http from '@/api/index'
import dayjs from 'dayjs'

export default {
  name: 'MypageMyPage',
  props: [
    'isLogin',
    'reserveData'
  ],
  components: {
    VueperSlides,
    VueperSlide,
    'barcode': VueBarcode
  },
  computed: {
    user() {
      return this.$store.getters['user']
    },
  },
  created () {
    this.init();
  },
  data () {
    return {
      year: dayjs().format('YYYY'),
      point: null,
      season: [],
      ticketing: [],
      reserveCnt: 0,
      ticketingCnt: 0,
      odds: 0,
      md: {}
    }
  },
  methods: {
    async init () {
      http.get('/mypage').then(res => {
        const data = res.data.data;

        this.point = data.point;
        this.season = data.season;
        this.md = data.md;
        if (this.md.length > 3) { // 상위 3개의 티켓리스트 보여
          return this.md.slice(0, 3) // 상위 3개의 티켓리스트 보여줌
        }
      }).catch(err => {
        console.log(err)
      })

      // 나의 티켓팅
      http.get('/app/mypage/home_odds').then(res => {
        const data = res.data.body[0];
        this.odds = Math.ceil(data.accrue_odds*100);
      }).catch(err => {
        console.log(err)
      })

    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    }
  }

}
</script>

