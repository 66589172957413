<template>
  <div>
    <div class="listInfo">
      <div class="title hiddenMobile">시즌티켓 실사용자 관리</div>
    </div>
    <div class="dataTable">
      <div>
        <table>
          <thead>
            <tr>
              <th>예매번호</th>
              <th>티켓번호</th>
              <th>구매상품</th>
              <th>구매일시</th>
              <th>실사용자 이름</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in seasonPassList" v-bind:key="row.ticket_no">
              <td class="no">{{ row.reserve_no }}</td>
              <td>{{row.ticket_no}}</td>
              <td class="left">
                <div class="league">{{ row.match_info }}</div>
                <div class="bold">{{ row.product_denomination_name}}</div>
                <div>{{row.tl_seat_info}}</div>
              </td>
              <td class="date">{{ dayjs(row.reserve_datetime).format("YYYY-MM-DD HH:mm:ss") }}</td>
              <td><input class="input" type="text" name="real_name" v-model="row.real_user_name" :disabled="row.disabled" maxlength="15"/></td>
              <td class="btns">
                <button type="button" class="btn w100" @click="enableName(row.ticket_no)" v-if="row.status">이름변경</button>
                <button type="button" class="btn w100" @click="changeName(row.ticket_no)" v-if="!row.status">저장</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="noData" v-if="seasonPassList.length === 0">
        시즌티켓 구매내역이 없습니다
      </div>
        <p style=" margin-top: 20px; font-size: 16px;">* 시즌티켓 실사용자 명 입력 시 부주의로 인한 책임은 실사용자에게 있습니다.</p>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs'
import http from "@/api/mypageApi";

export default {
  name: 'MypagePaySeasonRealname',
  components: {

  },
  computed: {
    list () {
      return true
    }
  },
  created() {
    const termFrom = dayjs().startOf('year').format()
    const termTo = dayjs().format()
    this.term = {
      from: termFrom,
      to: termTo
    }
    this.init()
  },
  data () {
    return {
      seasonPassList: []

    }
  },
  methods: {
    dayjs,
    init () {
      this.getData();
    },
    async getData () {
      const res = await http.get(`/seasonpass/realname`);
      const data = res.data.data;

      this.seasonPassList = data.map(row => {
        return {
          ...row,
          disabled: true,
          status: true
        }
      });
    },
    enableName(ticket_no) {
      const index = this.seasonPassList.findIndex(row => row.ticket_no === ticket_no);
      this.seasonPassList[index].disabled = false;
      this.seasonPassList[index].status = false;
    },

    changeName(ticket_no) {
      const index = this.seasonPassList.findIndex(row => row.ticket_no === ticket_no);
      const real_name = this.seasonPassList[index].real_user_name;

      // real_name 15자 확인
      if(real_name.length > 15) {
        alert('15자 이하로 입력해주세요.');
        return;
      }

      http.post(`/seasonpass/realname`, {ticket_no, real_name}).then(res => {
        if(res.status === 200) {
          alert('이름이 변경되었습니다.');
          this.seasonPassList[index].disabled = true;
          this.seasonPassList[index].status = true;
        }
      });







    }

  }
}
</script>

<style lang="scss" scoped>
.no2 {
  display: none;
}
@media screen and (max-width: 767px) {
  tbody {
    .no {
      display: none;
    }
    .league {
      margin-bottom: 10px;
    }
    .date {
      margin-top: 20px;
      color: $grey3;
    }
    .qty,
    .status {
      display: inline-block;
      vertical-align: middle;
    }
    .no2 {
      display: inline-block;
      vertical-align: middle;
    }
    .no2:after,
    .status:before {
      content: "|";
      margin: 0 8px;
      color: $lightgrey1;
    }
  }
}
</style>
