<template>
  <!-- fix 2025 season --- 주석해제 -->
  <!--    <div class="introSection section1_1"  :style="{height: `${sectionHeight}px`}">-->
  <!-- //fix 2025 season --- 주석해제 -->
  <!-- fix 2025 season --- 삭제 -->
  <div class="introSection section1_1" style="min-height: 500px;">
    <!-- //fix 2025 season --- 삭제 -->
    <div class="section1_1Title">대표이사 인사말</div>
    <div class="section1_1Img"><img style="display: none" :src="require('@/assets/img/history/greeting.jpg')" alt=""></div>
    <div class="greeting" style="font-weight: 400">
      <div class="container" style="max-width: 1300px" v-html="greeting[lang]"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClubCeo',
  props: [
    'active',
    'height',
    'lang'
  ],
  computed: {
    isActive () {
      return this.active && this.active.index === 2
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 4000
      // height * 4 추후 인물사진 기입 시 원복
      return height * 1.7
    }
  },
  // beforeDestroy () { //fix 2025 season --- 주석해제
  //   document.removeEventListener('scroll', this.onScroll)
  //   window.removeEventListener('resize', this.onResize)
  // },
  // mounted () { //fix 2025 season --- 주석해제
  //   document.addEventListener('scroll', this.onScroll)
  //   window.addEventListener('resize', this.onResize)
  //   this.onScroll()
  //   this.onResize()
  // },
  data () {
    return {
      parallaxWidth: false,
      greeting: {
        ko: `준비중입니다.`,
        en: `준비중입니다.`,
      }
    }
  },
  methods: {
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section1_1')
      const scrollY = window.scrollY
      if (section) {
        const sectionTitle = section.querySelector('.section1_1Title')
        const img = section.querySelector('.section1_1Img')
        const imgObj = img.querySelector('img')
        const greeting = section.querySelector('.greeting')
        const scrollDiff = section.offsetTop - scrollY
        const scrollPercent = parseInt(this.active && this.active.scrollPercent) >= 33.33 ? 100 : parseInt(this.active && this.active.scrollPercent) * 3
        const pos = scrollDiff > 0 ? 0 : scrollDiff * -1
        const imgOffset = img.offsetTop
        const greetingPos = greeting.getBoundingClientRect().top
        if (isActive && section && this.parallaxWidth) {
          if (pos >= imgOffset) {
            sectionTitle.style.color = '#fff'
            img.style.transform = `translateY(${pos + 50 - imgOffset}px)`
            img.style.width = `${62.5 + ((37.5) * scrollPercent / 100)}%`
            img.style.width = '100%'
            imgObj.style.opacity = scrollPercent > 50 ? 0.5 : 1 - (scrollPercent / 100)
          } else {
            sectionTitle.style.color = ''
            img.style.transform = ''
            img.style.width = ''
            imgObj.style.opacity = ''
          }
          // title
          if (greetingPos > sectionTitle.offsetTop * 1.5) {
            sectionTitle.style.transform = `translateY(${pos}px)`
          }
        } else {
          sectionTitle.style.transform = ''
          sectionTitle.style.color = ''
          img.style.transform = ''
          img.style.width = ''
          imgObj.style.opacity = ''
        }
      }
    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
