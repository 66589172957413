<template>
  <div class="mainMatch">
    <div class="container">
      <div class="matchContent">
        <!-- 지난경기 -->
        <div class="matchBox prev">
          <div class="inner">
            <div class="boxTitle">지난 경기 결과</div>
            <!-- 지난경기 박스 -->
            <div v-if="prevMatch && prevMatch.length > 0" class="prevMatch">
              <vueper-slides :fixed-height="'506px'" :autoplay="false" :infinite="false" :duration="5000"
                :bullets="false" :touchable="false" :pause-on-hover="false" :pause-on-touch="false"
                :disable-arrows-on-edges="true" class="no-shadow">
                <vueper-slide v-for="(match, index) in prevMatch" :key="index">
                  <template #content>
                    <div v-for="(item, i) in match" :key="i" class="matchItem">
                      <div v-if="item">
                        <div class="date">{{ item.game_date }}({{ item.yoil }})</div>
                        <div class="matchInfo">
                          <div class="home">
                            <img
                              :src="item.homeLogoImg"
                              alt="">
                            <div class="name pretendard">{{ item.front_home_team_name }}</div>
                          </div>
                          <div class="info">
                            <div class="score">
                              <span class="point">{{ item.front_home_team_goal }}</span> :
                              <span>{{ item.back_home_team_goal }}</span>
                            </div>
                            <div v-if="item.home === true" class="game" data-game="home">홈</div>
                            <div v-else class="game" data-game="away">원정</div>
                          </div>
                          <div class="away">
                            <img :src="item.awayLogoImg"
                              alt="">
                            <div class="name pretendard">{{ item.back_away_team_name }}</div>
                          </div>
                        </div>
                        <div class="btns">
                          <a class="btn" :href="`/match/game/${item.game_date_url}/summary?type=${item.game_league_set}`" v-if="item.front_home_team_goal !== null && item.isKleage">경기기록</a>
                          <a v-if="item.highlight !== null " class="btn grey1" :href="`/match/game/${item.game_date_url}/highlight?type=${item.game_league_set}`">하이라이트</a>
                        </div>
                      </div>
                    </div>
                  </template>
                </vueper-slide>
                <template #arrow-left>
                  <img src="~@/assets/img/btn_arr_left.png" alt="">
                </template>
                <template #arrow-right>
                  <img src="~@/assets/img/btn_arr_right.png" alt="">
                </template>
              </vueper-slides>
            </div>
            <div v-else class="noData">
              지난 경기 결과가 없습니다.
            </div>
            <!-- //지난경기 박스 -->
          </div>
        </div>
        <!-- //지난경기 -->
        <!-- 다음경기 -->
        <div class="matchBox next">
          <div class="inner">
            <div class="boxTitle">다음 경기 일정</div>
            <!-- 다음경기 박스 -->
            <div v-if="nextMatch && nextMatch.length > 0" class="prevMatch">
              <vueper-slides :fixed-height="'506px'" :autoplay="false" :infinite="false" :duration="5000"
                :bullets="false" :touchable="false" :pause-on-hover="false" :pause-on-touch="false"
                :disable-arrows-on-edges="true" class="no-shadow">
                <vueper-slide v-for="(item, index) in nextMatch" :key="index">
                  <template #content>
                    <div class="matchItem wide">
                      <div class="date">{{ item.game_date_view }}({{item.yoil}}) {{item.game_time_view}}</div>
                      <div class="matchInfo">
                        <div class="home">
                          <div class="name">{{ item.home_team_name }}</div>
                          <img :src="item.homeLogoImg"
                            alt="">
                        </div>
                        <div class="info">
                          <div class="vs">VS</div>
                          <div v-if="item.home === true" class="game" data-game="home">홈</div>
                          <div v-else class="game" data-game="away">원정</div>
                        </div>
                        <div class="away">
                          <img :src="item.awayLogoImg"
                            alt="">
                          <div class="name">{{ item.away_team_name }}</div>
                        </div>
                      </div>
                      <div class="stadium"><span>{{ item.field_name }}</span></div>
                      <Countdown :end-date="item.game_countDown" :label="true" :center="true" :color="'#f2cd00'"
                        :label-color="'#ffffff'" class="pretendard" />
                      <div class="btns">
                        <router-link v-if="item.home === true" class="btn yellow" :to="{name: 'ticket'}">티켓예매</router-link>
                        <a href="https://www.kleague.com/schedule.do?leagueId=1" target="_blank" v-else class="btn yellow" >티켓예매</a>
<!--                        <a href="#" v-if="item.home === true" class="btn grey1">이벤트안내</a>-->
<!--                        <router-link href="#" v-if="item.home === true && isEvent === true" class="btn grey1" :to="{name: 'fanzoneEvent'}">이벤트안내</router-link>//추후 주석해제 -->
                        <router-link href="#" v-if="item.home === true && isEvent === true" class="btn grey1" :to="{name: 'fanzoneEvent'}">이벤트신청</router-link>
                      </div>
                    </div>
                  </template>
                </vueper-slide>
                <template #arrow-left>
                  <img src="~@/assets/img/btn_arr_left.png" alt="">
                </template>
                <template #arrow-right>
                  <img src="~@/assets/img/btn_arr_right.png" alt="">
                </template>
              </vueper-slides>
            </div>
            <div v-else class="noData">
              다음 경기 일정이 없습니다.
            </div>
            <!-- //지난 경기 결과가 없습니다. 를 내가 바꿈 -->
            <!-- //다음경기 박스 -->
          </div>
        </div>
        <!-- //다음경기 -->
        <!-- 순위 -->
        <div class="matchBox ranking">
          <!-- 팀순위 -->
          <div class="inner">
            <!--  추후 ACL 조별예선순위 살릴시 주석해제 -->
<!--            <span :style="{ color: leagueRankColor }"><button @click="changeRank('league')" :disabled="ranking_show">리그 순위</button></span>-->
<!--            <span style="color: rgba(255, 255, 255, 0.5);">&nbsp;&nbsp;|&nbsp;&nbsp;</span>-->
<!--            <span :style="{ color: aclRankColor }"><button  @click="changeRank('acl')" :disabled="!ranking_show">ACL 조별 예선 순위</button></span>-->
            <!--  추후 ACL 조별예선순위 살릴시 주석해제 -->
<!--            <p class="info">업데이트 날짜 들어가는 곳</p>-->
            <span :style="{ color: leagueRankColor }">대회 순위</button></span>
            <div class="rankingBox" v-if="ranking_show">
              <table>
                <thead>
                <tr>
                  <th>순위</th>
                  <th>팀</th>
                  <th>경기</th>
                  <th>승</th>
                  <th>무</th>
                  <th>패</th>
                  <th>승점</th>
                </tr>
                </thead>
                <tbody v-if="ranking.length > 0">
                <tr v-for="team in ranking" :key="team.ranking" :class="{ active: team.active }">
                  <td class="rank">{{ team.rank }}</td>
                  <td class="team"><img
                      :src="team.teamLogoImg">{{ team.team_name }}
                  </td>
                  <td class="game">{{ team.game_count }}</td>
                  <td class="win">{{ team.win_cnt }}</td>
                  <td class="draw">{{ team.tie_cnt }}</td>
                  <td class="lose">{{ team.loss_cnt }}</td>
                  <td class="point">{{ team.gain_point }}</td>
                </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="noData league_noData">
                      데이터가 없습니다.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="rankingBox" v-else>
              <table>
                <thead>
                <tr>
                  <th>순위</th>
                  <th>팀</th>
                  <th>경기</th>
                  <th>승</th>
                  <th>무</th>
                  <th>패</th>
                  <th>승점</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="team in rankingACL" :key="team.ranking" :class="{ active: team.active }">
                  <td class="rank">{{ team.rank }}</td>
                  <td class="team">
                    <img :src="team.teamLogoImg">
                    {{ team.team_name }}
                  </td>
                  <td class="game">{{ team.game_count }}</td>
                  <td class="win">{{ team.win_cnt }}</td>
                  <td class="draw">{{ team.tie_cnt }}</td>
                  <td class="lose">{{ team.loss_cnt }}</td>
                  <td class="point">{{ team.gain_point }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- //팀순위 -->
          <!-- 배너 -->
          <div class="banner bannerSlider">
            <vueper-slides v-if="banner" :autoplay="!isMobile" :duration="2500" Prevent-y-scroll :infinite="true" :arrows="false"
              :slideRatio="157 / 364" :touchable="isMobile" :pause-on-hover="false" :pause-on-touch="false"
              class="no-shadow">
              <vueper-slide v-for="(item, index) in banner" :key="index">
                <template #content>
                  <a :href="item.link" :target="item.newWindow ? '_blank' : '_self'">
                    <img :src="item.thumbnail">
                  </a>
                </template>
              </vueper-slide>
              <!-- 슬라이더 인디케이터 -->
              <template #bullets="{ bulletIndexes, goToSlide, previous, next, currentSlide }">
                <span class="current">{{ currentSlide + 1 }}</span> / {{ banner.length }}
              </template>
              <!-- //슬라이더 인디케이터 -->
            </vueper-slides>
          </div>
          <!-- //배너 -->
        </div>
        <!-- //순위 -->
      </div>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Countdown from '@/components/Util/Countdown'
import http from '@/api/index'
import dayjs from 'dayjs'
import lib from '@/library/common'
import imgPath from "@/library/imgPath"

export default {
  name: 'MainMatch',
  components: {
    VueperSlides,
    VueperSlide,
    Countdown
  },
  watch: {
    isMobile(isMobile) {
      const flatten = this.getFlatArr(this.prevMatch)
      this.prevMatch = this.getMultipleArr(flatten, isMobile ? 1 : 2)
    }
  },
  created() {
    this.thisYear = this.$store.state.thisYear
    this.init()
    this.onResize()
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  data() {
    return {
      isMobile: false,
      prevMatch: [],
      nextMatch: [],
      banner: null,
      ranking: [],
      isEvent: false,
      teamRanking: 1,
      ranking_show: true,
      leagueRankColor: '',
      aclRankColor: 'rgba(255, 255, 255, 0.5)',
      thisYear: '',
      noData_league: true,
    }
  },
  methods: {
    changeRank(rankType) {
      if (rankType === 'league') {
        this.leagueRankColor = ''
        this.aclRankColor = 'rgba(255, 255, 255, 0.5)'
      } else if (rankType === 'acl') {
        this.leagueRankColor = 'rgba(255, 255, 255, 0.5)'
        this.aclRankColor = ''
      }
      this.ranking_show = !this.ranking_show
    },
    async init() {
      const bannerContent = await http.get('/main/banner')
      // 지난경기결과
      const lastMatch_res = await http.get('/main/last_match')
      lastMatch_res.data.data.map(row => {
        row.game_date_url = row.game_date
        row.game_date = dayjs(row.game_date).format('YYYY.MM.DD')
        if (row.home_team === 'K05') {
          row.front_home_team_name = row.home_team_name;
          row.front_home_team = row.home_team;
          row.front_home_team_goal = row.home_team_goal;
          row.back_away_team_name = row.away_team_name;
          row.back_away_team = row.away_team;
          row.back_home_team_goal = row.away_team_goal;
          row.home = true
        } else {
          row.front_home_team_name = row.away_team_name;
          row.front_home_team = row.away_team;
          row.front_home_team_goal = row.away_team_goal;
          row.back_away_team_name = row.home_team_name;
          row.back_away_team = row.home_team;
          row.back_home_team_goal = row.home_team_goal;
          row.home = false
        }
        if (row.front_home_team_name === '라이언시티') {
          row.front_home_team_name = '라이언'
        }
        if (row.back_away_team_name === '라이언시티') {
          row.back_away_team_name = '라이언'
        }
        switch (row.meet_name) {
          case 'K League 1':
            row.game_league_set = 'kleague'
            row.isKleage = true
            break
          case 'ACL':
            row.game_league_set = 'afc'
            break
          case 'koreancup':
            if (this.currentYear < 2024) {
              row.game_league_set = 'facup'
            } else {
              row.game_league_set = 'koreancup'
            }
            break
          case 'ETC':
            row.game_league_set = 'etc'
            break
        }
        row.homeLogoImg = imgPath.setTeamLogo(row.front_home_team)
        row.awayLogoImg = imgPath.setTeamLogo(row.back_away_team)
      })
      const prevMatch = lastMatch_res.data.data
      prevMatch.map(row=>{
        row.leagueName = lib.getLeagueName(row.meet_name)
      })
      // 다음경기일정
      const NextMatch_res = await http.get('/main/next_match')
      NextMatch_res.data.data.map(row => {
        row.game_date_view = dayjs(row.game_date).format('YYYY.MM.DD')
        row.game_time_view = row.game_time.slice(0, 5)
        row.home = row.home_team === 'K05'
        row.game_countDown = `${row.game_date} ${row.game_time}`
        row.homeLogoImg = imgPath.setTeamLogo(row.home_team)
        row.awayLogoImg = imgPath.setTeamLogo(row.away_team)
        if (row.away_team_name === '라이언시티') { // 추후 이름 긴 팀 이름 일괄수정필요
          row.away_team_name = '라이언'
        }
        if (row.home_team_name === '라이언시티') {
          row.home_team_name = '라이언'
        }
      })
      const nextMatch = NextMatch_res.data.data
      // 리그순위
      const teamRank_res = await http.get('/main/team_rank')
      if (teamRank_res.data.data) {
        teamRank_res.data.data.map(row => {
          row.teamLogoImg = imgPath.setTeamLogo(row.team_id)
        })
      }
      const ranking = teamRank_res.data.data
      // ACL 리그순위
      const rankingACL_res = await http.get(`/match/acl_rank?year=${this.thisYear}`)
      if (rankingACL_res.data.data) {
        rankingACL_res.data.data.map(row => {
          row.teamLogoImg = imgPath.setTeamLogo(row.team_id)
        })
      }
      const rankingACL = rankingACL_res.data.data

      this.prevMatch = this.getMultipleArr(prevMatch, 2)
      this.nextMatch = nextMatch

      if (ranking) {
        this.ranking = ranking.map((x) => {
          return {
            ...x,
            active: x.team_id === "K05"
          }
        })
      }

    if (rankingACL_res) {
      this.rankingACL = rankingACL.map((x) => {
        return {
          ...x,
          active: x.team_id === "K05"
        }
      })
    }

      this.banner = bannerContent.data.body
      await this.setEvent()
    },
    getMultipleArr(data = [], size = 1) { // pc에서 이중배열 (요소 2개씩)
      const items = [...data]
      const arr = []
      while (items.length) {
        arr.push(items.splice(0, size))
      }
      return arr
    },
    getFlatArr(arr) { // pc -> 모바일 데이터 배열을 위한 flatten
      const flatten = arr.flat(Infinity).filter(x => x)
      return flatten
    },
    onResize() {
      this.isMobile = window.innerWidth < 768
    },
    setEvent () {
      const eventList = this.$store.state.nav.eventList
      const nowTime = dayjs()
      const result = eventList.filter(row => {
        return nowTime.isAfter(row.end_apply) === false
      })
      result.length > 0 ? this.isEvent = true : this.isEvent = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .league_noData{
    height: 280px !important;
    position: absolute !important;
    padding-bottom: 40px !important;
    background-position: 50% calc(60% - 75px) !important;
    right: 110px !important;
  }
  @media screen and (max-width: 1199px) {
    .league_noData{
      right: 0 !important;
      left: 20px !important;
    }
  }

</style>

