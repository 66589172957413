<template>
  <div class="reservationIntro">
    <!-- 다음홈경기 -->
    <div class="nextMatch matchPage" v-if="state">
      <div class="container">
        <div class="sectionTitle sectionTitle2">다음 홈경기</div>
        <!-- 리스트 -->
        <div class="matchList">
          <!-- 매치 -->
          <div class="item" v-for="(item, i) in match" :key="i">
            <div class="info">
              <div class="league" :data-league="item.game_league"></div>
              <div class="time">D-{{item.d_day}}</div>
              <div class="date">{{item.game_matchDate}} ({{item.yoil}})</div>
              <div class="stadium">{{item.field_name}}</div>
            </div>
            <div class="game emblem">
              <div class="team left">
                <img :src="item.homeLogo" class="emblem" alt="">
                <span class="name">{{item.home_team_name}}</span>
              </div>
              <div class="center">
                vs
              </div>
              <div class="team right">
                <img :src="item.awayLogo" class="emblem" alt="">
                <span class="name">{{item.away_team_name}}</span>
              </div>
            </div>
            <div class="btns mobileFlex">
              <div class="row">
                <button class="btn" @click="purchase">{{item.isBtn ? item.btnText : '티켓예매'}}</button>
              </div>
            </div>
          </div>
        </div>
        <!--// 매치 -->
        <!-- //리스트 -->
      </div>
      <div class="container">
        <img src="@/assets/img/ticketPolicy_2025_1.jpg" alt=""/>
        <img src="@/assets/img/ticketPolicy_2025_2.jpg" alt=""/>
      </div>
    </div>
    <!-- //다음홈경기 -->
    <!-- 티켓정책 -->
    <div class="sectionBox" v-if="!state">
      <div class="container">
        <div class="sectionTitle">티켓정책</div>
        <TicketPolicy />
        <div class="termbox">
          <span>티켓 정책 안내사항</span>
          <div class="ulbox">
            <ul>
              <li>어린이 : 24개월 이상 ~ 초등6학년 (어린이 입장권 적용)</li>
              <li>유아 : 24개월 미만 → 무료 입장 가능 (좌석 점유 불가)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- //티켓정책 -->
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 80px;" v-if="!state">
      <div class="container">
        <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>
      </div>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import regExp from '@/common/regExp'
import TicketPolicy from './TicketPolicy'
import http from '@/api/index'
import imgPath from "@/library/imgPath"
import dayjs from "dayjs"
import {reservePopup} from "@/library/ticketlink"
import router from "@/router"

export default {
  name: 'TicketReservationIntro',
  components: {
    TicketPolicy
  },
  mounted() {
    if (localStorage.getItem('favorite')) {
      const favorite = JSON.parse(localStorage.getItem('favorite'))
      if (favorite === null) {
        if (this.isLogin !== false) {
          if (window.confirm('필수 추가 정보를 입력하지 않아, 티켓 예매 서비스를 이용하실 수 없습니다.\n' +
              '필수 추가 정보를 기입하시겠습니까? ')) {
            return this.$router.push('/member/myinfo')
          } else {
            return this.$router.go(-1)
          }
        }
      }
    }
    this.getEncryptedId()
  },
  created() {
    this.init()
  },
  computed:{
    reserveText() {
      const today = dayjs().format('YYYY.MM.DD HH:mm:ss')
      if (today < '2023.02.28 12:00:00'){
        return true
      } else {
        return false
      }
    },
    isLogin() {
      return this.$store.getters['isLogin']
    }
  },
  data () {
    return {
      state: true,
      match: [],
      encrypted_id: '',
    }
  },
  methods: {
    async init () {
      const match = await http.get('/ticket/next_match')
      this.match = match.data.body
      const today = dayjs().format('YYYY-MM-DD')
      this.match.map(el => {
        el.game_settime = el.game_time.substring(0, 5)
        el.game_matchDate = dayjs(el.game_date).format("YYYY.MM.DD")
        el.ticket_openDate = dayjs(el.game_date).subtract(5, 'd').format("MM.DD")
        el.ticket_setOpenDate = dayjs(el.game_date).subtract(5, 'd').hour(12).minute(0).second(0);
        el.game_setCountDown = `${el.game_date} ${el.game_settime}`
        el.game_countDown = dayjs(el.game_date + el.game_time).format("YYYY.MM.DD HH:mm:ss")
        el.expired_at = dayjs(el.game_date)
        el.d_day = Math.floor(el.expired_at.diff(today, "day", true))
        el.homeLogo = imgPath.setTeamLogo(el.home_team)
        el.awayLogo = imgPath.setTeamLogo(el.away_team)
        el.btnText = `${el.ticket_openDate} 12:00 티켓오픈`
        switch (el.meet_name) {
          case 'K League 1':
            el.game_league = 'kleague'
            break
          case 'ACL':
            el.game_league = 'afc'
            break
          case 'KOREAN CUP':
            el.game_league = 'KOREAN CUP'
            break
        }
        if (dayjs() < el.ticket_setOpenDate) {
          el.isBtn = true
        } else {
          el.isBtn = false
        }
      })
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    getEncryptedId() {
      if(this.$store.state.user.encrypted_id !== null) {
        this.encrypted_id = this.$store.state.user.encrypted_id
      } else {
        this.encrypted_id = ''
      }
    },
    purchase() {
        this.getEncryptedId()
        if(this.encrypted_id !== '') {
          const url = reservePopup(this.encrypted_id, "pc");
          window.gtag('event','btn_구매하기',{'event_category':'시즌권', 'event_label':this.$store.state.user.id})
          window.open(url,'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
        } else {
          alert("통합회원 로그인 후 이용해주세요.")
          const redirectUrl = router.currentRoute.path;
          router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.termbox{
  margin-top: 30px;
  font-weight: 700;
  font-size: 16px;
}
.ulbox{
  margin-left: 20px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #777777;
  li{
    list-style: disc;
  }
}
</style>
