<template>
  <div class="clubIntro hyundai">
    <div class="introSection section1 center" :style="styleHeight">
      <div class="video">
        <video autoplay playsinline muted loop preload>
          <source :src="this.video" type="video/mp4" />
        </video>
      </div>
      <div class="newSloganBox"></div>
<!--      <div class="sloganBox">-->
<!--        <div class="slogan"></div>-->
<!--        <div class="team">{{slogan[lang]}}</div>-->
<!--      </div>-->
    </div>
    <!-- fix 250106 대표이사 인사말 영역 삭제 -->
    <Ceo
        v-show="false"
        :active="activeSection"
        :style="styleHeight"
        :height="styleHeight"
        :lang="lang" />
    <!-- //fix 250106 대표이사 인사말 영역 삭제 -->
    <Stadium
      :active="activeSection"
      :style="styleHeight"
      :height="styleHeight"
      :lang="lang" />
    <Emblem
      :active="activeSection"
      :style="styleHeight"
      :height="styleHeight"
      :lang="lang" />
    <Mascot
      :active="activeSection"
      :style="styleHeight"
      :height="styleHeight"
      :lang="lang" />
    <Clubhouse
      :active="activeSection"
      :style="styleHeight"
      :height="styleHeight"
      :lang="lang" />
  </div>
</template>

<script>
import Stadium from './ClubStadium.vue'
import Emblem from './ClubEmblem.vue'
import Mascot from './ClubMascot.vue'
import Clubhouse from './ClubClubhouse.vue'
import Ceo from './ClubCeo.vue'
import imgPath from '@/library/imgPath'

export default {
  name: 'ClubIntro',
  components: {
    Stadium,
    Emblem,
    Mascot,
    Clubhouse,
    Ceo
  },
  computed: {
    styleHeight () {
      return {
        minHeight: this.sectionHeight
      }
    },
    lang () {
      return this.$route.params.lang || 'ko'
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    const lang = this.lang
    this.getWinHeight()
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
    document.querySelector('.clubIntro').setAttribute('lang', lang)
    this.video = imgPath.setVideo('bg_club_stadium_ver2')
  },
  data () {
    return {
      sectionHeight: '860px',
      activeSection: null,
      parallaxWidth: false,
      slogan: {
        ko: 'JEONBUK HYUNDAI MOTORS FOOTBALL CLUB',
        en: 'JEONBUK HYUNDAI MOTORS FOOTBALL CLUB'
      },
      video : ''
    }
  },
  methods: {
    getWinHeight () {
      this.sectionHeight = `${window.innerHeight}px`
    },
    onScroll () {
      const sections = document.querySelectorAll('.introSection')
      const position = sections ? [...sections].map((x, i) => {
        return {
          section: i + 1,
          height: x.clientHeight,
          top: x.offsetTop,
          bottom: x.offsetTop + x.clientHeight - 1
        }
      }) : null
      const scrollY = window.scrollY
      const section = document.querySelector('.section1')
      function getRange (scroll, position) {
        const section = position ? position.find(x => {
          return scroll >= x.top && scroll <= x.bottom
        }) : null
        return section ? {
          index: section.section,
          scrollPercent: ((scroll - section.top) / section.height * 100).toFixed(2) // 현재 섹션에서 스크롤 위치가 몇%인지
        } : null
      }
      this.activeSection = getRange(scrollY, position)
      // if (section && this.activeSection && this.activeSection.index === 1) {
      //   const slogan = section.querySelector('.sloganBox')
      //   console.log(slogan)
      //   const scrollDiff = scrollY
      //   console.log(scrollY)
      //   if (this.parallaxWidth) {
      //     slogan.style.transform = `translateY(${scrollDiff}px)`
      //   } else {
      //     slogan.style.transform = ''
      //   }
      //   section.querySelector('.video').style.zIndex = ''
      // } else {
      //   section.querySelector('.video').style.zIndex = -1
      // }

    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
<style lang="scss">
 .newSloganBox {
   position: relative;
   width: 1000px;
   height: 300px;
   background: url('~@/assets/img/club/club_slogan.png') 100% 100% / contain no-repeat;
 }

 @media screen and (max-width: 1022px) {
   .newSloganBox{
     width: 800px;
   }
 }

 @media screen and (max-width: 767px) {
   .newSloganBox{
     width: 250px;
     height: 100px;
   }
 }
</style>

