<template>
  <div class="introSection section2">
    <div class="sectionTitle team">
      JEONBUK HYUNDAI MOTORS<br>FOOTBALL CLUB HISTORY
    </div>
    <div class="yearShortcut">
      <button
        v-for="year in years"
        :key="year"
        type="button"
        class="shortcut"
        :data-year="year"
        @click="yearShortcut(year)">
        {{year}}
        <div class="img">
          <div class="bg"><img :src="require(`@/assets/img/history/bg_history_${year}.png`)" alt=""></div>
          <div class="player"><img :src="require(`@/assets/img/history/player_history_${year}.png`)" alt=""></div>
        </div>
      </button>
    </div>
    <div class="yearHistorySection">
      <div class="sectionTitle">연도별 역사</div>
      <div class="years">
        <button
          v-for="year in years"
          :key="year"
          type="button"
          :class="{active: activeYear === year}"
          @click="yearShortcut(year)">
          {{year}}
        </button>
      </div>
      <div class="swiper-button-prev yearSwiperPrev" slot="button-prev"></div>
      <div class="swiper-button-next yearSwiperNext" slot="button-next"></div>
      <swiper class="swiper yearSwiper"
        ref="yearSwiper"
        :options="swiperOption">
        <swiper-slide
          v-for="(item, index) in activeHistory"
          :key="index"
          class="swiperItem">
          <div v-if="item === 'blank'"></div>
          <div v-else>
            <div class="year">{{item.year}}</div>
            <div class="slideInner">
              <img v-if="item.img" :src="require(`@/assets/img/history/${item.img}.png`)" alt="">
              <ul class="historyList">
                <li
                  v-for="(history, index) in item.history"
                  :key="index">
                  {{history}}
                </li>
              </ul>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'HistoryYear',
  props: [
    'active'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    isActive () {
      return this.active && this.active.index === 2
    },
    years () {
      return Object.keys(this.history)
    },
    activeHistory () {
      const year = this.activeYear
      return window.innerWidth >= 768 ? [ 'blank', ...this.history[year], 'blank'] : this.history[year]
    }
  },
  data () {
    return {
      index: 1,
      activeYear: '1990',
      history: {
        '1990': [
          { year: '1994', history: ['전북다이노스 축구단 창단'] },
          { year: '1995', history: ['아디다스컵 4위', '하이트배 코리안리그 후기 4위', '하이트배 코리안리그 전기 7위'] },
          { year: '1996', history: ['프로축구 페어플레이상 수상', '라피도컵 정규리그 전기 5위', '아디다스컵 7위', '라피도컵 정규리그 후기 7위'] },
          { year: '1997', history: ['프로축구 공격상수상', '구단 명칭 변경(전북현대 다이노스 축구단)', '라피도컵 정규리그 6위', '구단 심벌마크 변경'] },
          { year: '1998', history: ['아디다스 코리아컵 B조 4위', '현대컵 K-리그 6위', '필립모리스코리아컵 7위'] },
          { year: '1999', history: ['제 4회 FA컵 준우승', '현대자동차 직영구단으로 전환', '한국 춘계실업축구연맹 준우승', '바이코리아컵 K-리그 7위', '구단주 취임(정몽구 현대기아자동차 회장)', '2군 운영'] }
        ],
        '2000': [
          { year: '2000', history: ['제5회 FA컵 우승', '삼성디지털 K-리그 3위', '대한화재컵 A조 3위', '구단 CI(엠블럼, 마스코트) 변경'] },
          { year: '2001', history: ['수퍼컵 준우승', 'FA컵 4위', '포스코 K-리그 9위'] },
          { year: '2002', history: ['아시안컵 위너스컵 준우승', '삼성 퍼브 K-리그 7위', '아디다스컵 A조 4위', 'FA컵 8강'] },
          { year: '2003', history: ['FA컵 우승', '정규리그 5위'], img: 'img_history_2003' },
          { year: '2004', history: ['수퍼컵 우승', 'AFC 챔피언스리그 4강', '하우젠컵 3위', '정규리그 통합 5위'], img: 'img_history_2004' },
          { year: '2005', history: ['FA컵 우승 (통산3회)', '중국 노능태산 친선경기', '컵대회 12위', '통영컵 국제프로축구대회 출전', 'K-리그 통합 12위 (전기 11위, 후기 12위)'], img: 'img_history_2005' },
          { year: '2006', history: ['AFC챔피언스리그 2006 우승', '슈퍼컵 준우승', 'FIFA 클럽월드컵 2006 5위', '정규리그 11위(전기7위 후기 13위)'], img: 'img_history_2006' },
          { year: '2007', history: ['AFC 챔피언스리그 2007 8강', '정규리그 8위'] },
          { year: '2008', history: ['태국 빅4컵 우승', 'FA컵 8강', '정규리그 4위'] },
          { year: '2009', history: ['정규리그 1위', 'FA컵 4강', '쏘나타 챔피언십 2009 우승'], img: 'img_history_2009' }
        ],
        '2010': [
          { year: '2010', history: ['쏘나타 K-리그 3위', 'FA컵 8강', '포스코컵 준우승', 'AFC 챔피언스리그 8강'] },
          { year: '2011', history: ['정규리그 1위', 'AFC 챔피언스리그 준우승', '현대오일뱅크 K리그 2011 챔피언십 우승'], img: 'img_history_2011' },
          { year: '2012', history: ['현대오일뱅크 K리그 준우승', 'FA컵 8강'] },
          { year: '2013', history: ['현대오일뱅크 K리그 3위', '하나은행 FA컵 준우승', 'ACL 16강'] },
          { year: '2014', history: ['현대오일뱅크 K리그 클래식 우승', '제19회 하나은행 FA컵 4강/ AFC 챔피언스리그 16강'], img: 'img_history_2014' },
          { year: '2015', history: ['현대오일뱅크 K리그 클래식 우승', '제20회 KEB 하나은행 FA컵 16강', 'AFC 챔피언스리그 8강'], img: 'img_history_2015' },
          { year: '2016', history: ['현대오일뱅크 K리그 클래식 준우승', '제21회 KEB 하나은행 FA컵 8강', 'AFC 챔피언스리그 우승'], img: 'img_history_2016' },
          { year: '2017', history: ['KEB 하나은행 K리그 클래식 우승', '제22회 KEB 하나은행 FA컵 32강'] },
          { year: '2018', history: ['KEB 하나은행 K리그1 우승', '제23회 KEB하나은행 FA컵 16강', 'AFC 챔피언스리그 8강'], img: 'img_history_2018' },
          { year: '2019', history: ['하나원큐 K리그1 우승'], img: 'img_history_2019' }
        ],
        '2020': [
          { year: '2020', history: ['하나원큐 K리그1 우승', '하나은행 FA컵 우승'], img: 'img_history_2020' },
          { year: '2021', history: ['하나원큐 K리그1 우승'], img: 'img_history_2021' },
          { year: '2022', history: ['하나원큐 FA컵 우승'], img: 'img_history_2022' },
          { year: '2023', history: ['하나원큐 K리그1 4위', '하나원큐 FA컵 준우승']},
          { year: '2024', history: ['하나은행 K리그1 10위']},
        ]
      },
      swiperOption: {
        loop: false,
        slidesPerView: window.innerWidth >= 768 ? 5 : 1.5,
        spaceBetween: 0,
        navigation: {
          nextEl: '.yearSwiperNext',
          prevEl: '.yearSwiperPrev'
        }
      }
    }
  },
  methods: {
    yearShortcut (year) {
      const top = document.querySelector('.section2').offsetTop + document.querySelector('.yearHistorySection').offsetTop
      const swiper = this.$refs.yearSwiper
      this.activeYear = year
      window.scrollTo(0, top)
      if (swiper) {
        swiper.initSwiper()
      }
    }
  }
}
</script>
