<template>
  <div>
    <div class="topTitle">
      <div class="title">전북현대<br>회원가입 동의과정</div>
      <div class="subTitle">전북현대모터스에프씨 웹사이트 가입을 위한 동의과정입니다.<br>필수 동의 항목은 미동의 시 가입이 불가능합니다.</div>
    </div>
    <!-- 동의사항 -->
    <div
      v-for="item in agree"
      :key="item.name"
      class="agreeBox">
      <div class="agreeLabel">
        {{item.label}} <span class="required">({{item.required ? '필수' : '선택'}})</span>
      </div>
      <div class="agreeContent">
        <div class="textarea" v-html="item.content"></div>
      </div>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
            type="radio"
            @click="agreeChk"
            :name="item.name"
            :id="`${item.name}_y`"
            value="y"
            v-model="item.agree">
          <label :for="`${item.name}_y`">동의</label>
        </div>
        <div class="checkbox radio">
          <input
            type="radio"
            @click="agreeChk"
            :name="item.name"
            :id="`${item.name}_n`"
            value="n"
            v-model="item.agree">
          <label :for="`${item.name}_n`">미동의</label>
        </div>
      </div>
    </div>
    <!-- //동의사항 -->
    <!-- 전체동의 -->
    <div class="agreeAll">
      <p class="label">상기 모든 내용(선택항목 포함)을 동의하시겠습니까?</p>
      <div class="checkAgree">
        <div class="checkbox radio">
          <input
            type="radio"
            name="agree_all"
            id="agree_all_y"
            v-model="agreeAll_yn"
            value="y"
            @change="setAgreeAll(true)">
          <label for="agree_all_y">동의</label>
        </div>
        <div class="checkbox radio">
          <input
            type="radio"
            name="agree_all"
            id="agree_all_n"
            v-model="agreeAll_yn"
            value="n"
            @change="setAgreeAll(false)">
          <label for="agree_all_n">미동의</label>
        </div>
      </div>
    </div>
    <!-- //전체동의 -->
    <div class="memberBtns">
      <button type="button" class="btn large mobileFull primary1" @click="nextStep">신규 회원가입<br>(통합 회원가입)</button>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
export default {
  name: 'SignupStep2',
  computed: {
    validate () {
      const disagree = this.agree.filter(x => {
        return x.required && (x.agree === 'n' || x.agree === '')
      }).map(x => x.label)
      return {
        result: disagree.length === 0,
        disagree: disagree.join(', ')
      }
    }
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  created() {
    http.get('/user/terms?type=register&key=')
        .then(res => {
          const terms = res.data.data
          this.agree[0].content = terms.policy_1 || '';
          this.agree[1].content = terms.policy_2 || '';
          this.agree[2].content = terms.policy_3 || '';
          this.agree[3].content = terms.policy_4 || '';
        })
        .catch(err => {
          console.log('>>>>>>SIGN ERR', err);
        })
  },
  data () {
    return {
      agreeAll_yn: '',
      agreeAll: true,
      agree: [
        {
          name: 'agree_1',
          label: '이용약관',
          required: true,
          content: '', // 추후 policy에 별도 html 불러오는 형태
          agree: ''
        },
        {
          name: 'agree_2',
          label: '개인정보 수집 및 이용에 대한 안내',
          required: true,
          content: '',
          agree: ''
        },
        {
          name: 'agree_3',
          label: '개인정보 처리위탁 안내',
          required: true,
          content: '',
          agree: ''
        },
        {
          name: 'agree_4',
          label: '개인정보의 마케팅 활용 및 광고성 메시지 수신 동의',
          required: false,
          content: '',
          agree: ''
        }
      ]
    }
  },
  methods: {
    setAgreeAll (agreeAll) {
      this.agree = this.agree.map(x => {
        return {
          ...x,
          agree: agreeAll ? 'y' : 'n'
        }
      })
      this.agreeAll_yn = agreeAll
    },
    nextStep () {
      if (this.validate.result) {
        if(this.agree[3].agree === ''){
          return alert(`개인정보의 마케팅 활용 및 광고성 메시지 수신 동의 항목의 동의 여부를 선택하지 않으셨습니다.`)
        }
        let agreeReceiveAdvertising = false
        this.agree[3].agree === 'y' // 광고성 수신 동의 값 4페이지 전달 위함
            ? agreeReceiveAdvertising = true
            : agreeReceiveAdvertising = false
        localStorage.setItem('agreeReceiveAdvertising', agreeReceiveAdvertising)
        this.$emit('nextStep', '3')
      } else {
        alert(`${this.validate.disagree}\n항목에 동의하지 않으셨습니다.`)
      }
    },
    agreeChk () {
      this.agreeAll_yn = this.agree.filter(x => x.agree === 'n').length === 0 ? 'n' : 'y'
    }
  },
  beforeDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  },
}
</script>
