<template>
  <div>
    <div v-if="open">
      <div class="sectionTitle line">시즌티켓 구매안내</div>
      <!--      <div class="sectionTitle">티켓 정책</div>-->
      <div class="seasonInfoCard">
        <div class="title">
          JEONBUK HYUNDAI
          <p>2025 <span class="mobileBreak">K LEAGUE SEASON TICKET</span><span class="mobileBreak"></span></p>
        </div>
        <div class="close">판매마감<br><strong>2025.01.21(화) 14:00</strong></div>
        <Countdown
            v-if="isOnTime"
            :endDate="time"
            :center="true"
            :label="true"
            :labelColor="'rgba(255,255,255,0.7)'"/>
        <div class="btns">
          <button type="button" class="btn yellow large" @click="purchase" :disabled="!isOnTime" v-if="isOnTime">구매하기</button>
        </div>
      </div>
      <div class="indexBox waitingSeason" style="margin-top: 80px;">
        <div class="container">
          <img :src="seasonImg_1" alt="2025 시즌권 구매정책"/>
          <img :src="seasonImg_2" alt="2025 시즌권 구매정책"/>
        </div>
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;" v-else>
      <img src="@/assets/img/bg_waiting2025Season.jpeg" alt="준비중입니다"/>
    </div>
  </div>
  <!-- //시즌 준비중 -->
</template>

<script>
import Countdown from '@/components/Util/Countdown'
import dayjs from "dayjs"
import { reservePopup } from '@/library/ticketlink'
import router from '@/router'
import imgPath from "@/library/imgPath";

export default {
  name: 'TicketSeasonInfo',
  components: {
    Countdown
  },
  computed : {
    open(){
      const time = dayjs('2025-01-07T05:00:00.000Z').format('YYYY-MM-DD HH:mm:ss')
      return !dayjs().isBefore(time);
    },
  },
  created () {
  },
  mounted() {
    this.getEncryptedId()
    setInterval(() => {
      this.isOnTime = this.checkIsOnTime()
    }, 500)
    const year = dayjs().year()
    this.seasonImg_1 = `${imgPath.imgPath.jbhd_s3}uploadFile/ticket/${year}season_1.jpg`
    this.seasonImg_2 = `${imgPath.imgPath.jbhd_s3}uploadFile/ticket/${year}season_2.jpg`
  },

  data () {
    return {
      datetime: null,
      encrypted_id: '',
      time: '2025-01-21T05:00:00.000Z',
      isOnTime: this.checkIsOnTime(),
      seasonImg_1: '',
      seasonImg_2: '',
    }
  },
  methods : {
    getEncryptedId() {
      if(this.$store.state.user.encrypted_id !== null) {
        this.encrypted_id = this.$store.state.user.encrypted_id
      } else {
        this.encrypted_id = ''
      }
    },
    checkIsOnTime() {
      const today = dayjs().toISOString();
      return dayjs(today).isBefore('2025-01-21T05:00:00.000Z');
    },
    purchase() {
      this.getEncryptedId()
      if(this.encrypted_id !== '') {
        const url = reservePopup(this.encrypted_id, "pc");
        window.gtag('event','btn_구매하기',{'event_category':'시즌권', 'event_label':this.$store.state.user.id})
        window.open(url,'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
      } else {
        alert("통합회원 로그인 후 이용해주세요.")
        const redirectUrl = router.currentRoute.path;
        router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
      }
    }
  },
}
</script>
