<template>
  <div>
    <div class="sectionTitle line">시즌권 안내</div>
    <div class="indexBox waitingSeason" style="margin-top: 80px;" v-if="state">
      <div class="container">
        <img src="@/assets/img/img_ticket_season2025_1.jpg" alt="준비중입니다"/>
        <img src="@/assets/img/img_ticket_season2025_2.jpg" alt="준비중입니다"/>
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;" v-else>
      <img src="@/assets/img/bg_waiting2025Season.jpeg" alt="준비중입니다"/>
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
export default {
  name: 'TicketSeasonIntro',
  data () {
    return {
      state: false, //fix 2025 season
    }
  },
}
</script>
