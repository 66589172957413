<template>
  <div>
    <div v-if="!complete">
      <div class="topTitle">
        <div class="title">전북현대<br>회원정보 통합</div>
        <div class="subTitle">전북현대모터스에프씨 웹사이트 가입을 환영합니다.<br>기존 웹사이트, 패밀리사이트 이용내용을 통합하실 수 있습니다.</div>
      </div>
      <!-- 이용내역 -->
      <div
        v-for="item in usageHistory"
        :key="item.name"
        class="agreeBox">
        <div class="agreeLabel">
          {{item.label}}
        </div>
        <div class="inBox">
          <div v-if="item.usageHistory">
            <p>이용한 내역이 있습니다. <span class="mobileBreak">새 웹사이트로 통합하시겠습니까?</span></p>
            <div class="checkAgree">
              <div class="checkbox radio">
                <input
                  type="radio"
                  :name="item.name"
                  :id="`${item.name}_y`"
                  value="Y"
                  v-model="item.agree">
                <label :for="`${item.name}_y`">동의</label>
              </div>
              <div class="checkbox radio">
                <input
                  type="radio"
                  :name="item.name"
                  :id="`${item.name}_n`"
                  value="N"
                  v-model="item.agree">
                <label :for="`${item.name}_n`">미동의</label>
              </div>
            </div>
          </div>
          <p v-else>이용한 내역이 없습니다.</p>
        </div>
      </div>
      <!-- //이용내역 -->
      <!-- 초록이네 동의사항 -->
      <div v-if="usage.greenStore.usageHistory && usage.greenStore.agree === 'Y'">
        <div
          v-for="item in greenStoreAgree"
          :key="item.name"
          class="agreeBox">
          <div class="agreeLabel">
            {{item.label}}<span class="required">({{item.required ? '필수' : '선택'}})</span>
          </div>
          <div class="agreeContent">
            <div class="textarea" v-html="item.content"></div>
          </div>
          <div class="checkAgree">
            <div class="checkbox radio">
              <input
                type="radio"
                :name="item.name"
                :id="`${item.name}_y`"
                value="Y"
                v-model="item.agree">
              <label :for="`${item.name}_y`">동의</label>
            </div>
            <div class="checkbox radio">
              <input
                type="radio"
                :name="item.name"
                :id="`${item.name}_n`"
                value="N"
                v-model="item.agree">
              <label :for="`${item.name}_n`">미동의</label>
            </div>
          </div>
        </div>
      </div>
      <!-- //초록이네 동의사항 -->
      <!-- 다음단계 -->
      <div class="memberBtns">
        <p class="confirmMessage">상기 내용을 토대로 통합을 진행하시겠습니까?<br>통합을 미동의한 경우 이후 회원정보 통합 이후 미동의한 내용을 통합 할 수 없습니다.</p>
        <button type="button" class="btn mobileFull large primary1" @click="setComplete">다음단계</button>
      </div>
      <!-- 다음단계 -->
    </div>
    <div v-else class="completeSignUp">
      <div class="topTitle">
        <div class="title">통합이 <span class="mobileBreak">완료되었습니다.</span></div>
        <div class="subTitle">전북현대모터스에프씨 웹사이트 가입을 환영합니다.</div>
        <div class="icoComplete"></div>
        <router-link :to="{name: 'signin'}" class="btn mobileFull large">로그인</router-link>
      </div>
    </div>
    <!-- 동의하지 않음을 선택한 항목이 있을 때 통합확인 레이어 -->
    <ConfirmLayer
      v-if="confirmLayer"
      :align="'center'"
      :message="confirmOption.message"
      :btn="confirmOption.btn"
      @confirm="integratedAll"
      @cancel="notIntegrated"
      @close="closeConfirmLayer" />
    <!-- //동의하지 않음을 선택한 항목이 있을 때 통합확인 레이어 -->
  </div>
</template>

<script>
import http from '@/api/index'
import ConfirmLayer from '@/components/Util/ConfirmLayer'


export default {
  name: 'SignupStep5',
  components: {
    ConfirmLayer
  },
  props: [
    'certInfo',
    'migration'
  ],
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage')
    document.getElementsByTagName('body')[0].classList.add('memberPage')
  },
  computed: {
    usageHistory () {
      return Object.keys(this.usage).map(x => {
        return this.usage[x]
      })
    },
    validate () {
      // 동의여부 선택 안함
      const missed = this.usageHistory.filter(x => {
        return x.usageHistory && x.required && x.agree === ''
      }).map(x => x.label)
      // 통합 미동의 항목이 있음
      const disagree = this.usageHistory.filter(x => {
        return x.agree === 'N'
      }).map(x => x.label)
      // 초록이네 통합 여부
      const isAgreeGreenStore = this.usage.greenStore.agree === 'Y'
      // 초록이네 미동의 항목이 있음
      const disagreeGreenStore = this.greenStoreAgree.filter(x => {
        return x.required && x.agree !== 'Y'
      }).map(x => x.label)

      return {
        missed: missed.join(', '),
        disagree: disagree.join(', '),
        ...isAgreeGreenStore && {
          disagreeGreenStore: disagreeGreenStore.join(', ')
        },
        // result: missed.length === 0 && ((isAgreeGreenStore && disagreeGreenStore.length === 0) || this.usage.greenStore.agree === 'n')
        result: missed.length === 0
      }
    }
  },
  created () {
    http.get('/user/terms?type=register&key=')
        .then(res => {
          const terms = res.data.data
          this.greenStoreAgree[0].content = terms.policy_5 || '';
          this.greenStoreAgree[1].content = terms.policy_6 || '';
        })
        .catch(err => {
          console.log('>>>>>>SIGN ERR', err);
        })

    this.usage.app.usageHistory = this.migration.oldMobile.exists;
    this.usage.site.usageHistory = this.migration.house.exists;
    this.usage.greenSchool.usageHistory = this.migration.greenSchool.exists;
    this.usage.greenStore.usageHistory = this.migration.greenStore.exists;
  },
  data () {
    return {
      complete: false,
      usage: {
        app: {
          name: 'app',
          label: '기존 모바일 애플리케이션',
          required: true,
          content: null, // null이면 통합 동의여부만 확인
          usageHistory: false,
          agree: ''
        },
        site: {
          name: 'site',
          label: '기존 웹사이트',
          required: true,
          content: null,
          usageHistory: false,
          agree: ''
        },
        greenSchool: {
          name: 'greenSchool',
          label: '기존 그린스쿨',
          required: true,
          content: null,
          usageHistory: false,
          agree: ''
        },
        greenStore: {
          name: 'greenStore',
          label: '기존 초록이네(온라인 MD샵)',
          required: true,
          content: null,
          usageHistory: false,
          agree: ''
        }
      },
      // 초록이네(온라인 MD샵) 이용내역이 있을 때 동의받을 내용
      greenStoreAgree: [
        {
          name: 'greenStore1',
          label: '초록이네(온라인 MD샵) 개인정보 이관 동의 안내',
          required: true,
          content: '',
          agree: ''
        },
        {
          name: 'greenStore2',
          label: '초록이네(온라인 MD샵) 제3자 정보제공 동의 안내',
          required: true,
          content: '',
          agree: ''
        }
      ],
      confirmLayer: false,
      confirmOption: {
        message: '',
        btn: {
          confirm: '모두 동의하여 통합 진행',
          cancel: '이대로 진행'
        }
      }
    }
  },
  methods: {
    closeConfirmLayer () {
      this.confirmLayer = false
    },
    setComplete () {
      if (this.validate.result) {
        if (this.validate.disagree) {
          this.confirmOption.message = `[${this.validate.disagree}]<br>미동의한 항목은 통합 이후 통합 할 수 없습니다.<br>미동의 항목을 동의하시겠습니까?`
          this.confirmLayer = true
        } else if (this.validate.disagreeGreenStore) {
          alert(`${this.validate.disagreeGreenStore}\n항목에 동의하지 않으셨습니다.`)
        } else {
          // 통합하고
          this.itergrated()
        }
      } else {
        if (this.validate.missed) {
          alert(`${this.validate.missed}\n에서 이용한 내역이 있습니다.\n통합 여부를 동의, 미동의하세요.`)
        }
        if (this.validate.disagreeGreenStore) {
          alert(`${this.validate.disagreeGreenStore}\n항목에 동의하지 않으셨습니다.`)
        }
      }
    },
    // 모두 통합하여 진행
    integratedAll () {
      const keys = this.usageHistory.filter(x => {
        return x.agree === 'N'
      }).map(x => x.name)

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        this.usage[key].agree = 'Y'
      }

      this.itergrated()
    },
    // 모두 통합하지 않고 현재 동의한 항목만 통합하여 그대로 진행
    notIntegrated () {
      // 초록이네 개인정보, 정보제공 동의 확인
      if (this.validate.disagreeGreenStore) {
        alert(`${this.validate.disagreeGreenStore}\n항목에 동의하지 않으셨습니다.`)
        this.closeConfirmLayer()
        return
      }

      this.itergrated()
    },
    itergrated () {
      // 통합 하고 완료
      // user_id, safe_di, mobile_app, website, greenschool, MD_shop, MD_personal_info, MD_personal_info_provide_agree
      const data = {
        user_id: this.certInfo.userId,
        safe_di: this.certInfo.dupinfo,
        mobile_app: this.usage.app.agree,
        website: this.usage.site.agree,
        greenschool: this.usage.greenSchool.agree,
        MD_shop: this.usage.greenStore.agree,
        MD_personal_info: this.usage.greenStore.agree === 'Y' ? 'Y' : '',
        MD_personal_info_provide_agree: this.usage.greenStore.agree === 'Y' ? 'Y' : ''
      }

      //console.log('통합 데이터 >>>>>>>>', data);
      http.post('/user/info_integrate', data)
        .then(res => {
          if (res.data.success) {
            alert('통합이 완료되었습니다.')
            this.confirmLayer = false
            this.complete = true
            window.scrollTo(0, 0)
          } else {
            alert('통합에 실패하였습니다.')
            this.confirmLayer = false
          }
        })
        .catch(err => {
          console.log(err);
          alert('통합에 실패하였습니다.')
          this.confirmLayer = false
        })

    }
  },
  beforeDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('memberPage')
    document.getElementsByTagName('body')[0].classList.remove('memberPage')
  }
}
</script>

<style lang="scss" scoped>
.icoComplete {
  width: 114px;
  height: 114px;
  margin: 0 auto 64px;
  background: url('~@/assets/img/ico_complete.png') 50% 50% / contain no-repeat;
}
.completeSignUp{
  margin: 10px 0;
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  color: #046A38;
}
</style>
