import Index from '@/views/Ticket.vue'
import Reservation from '@/components/Ticket/Reservation.vue'
import ReservationIntro from '@/components/Ticket/ReservationIntro.vue'
import ReservationHistory from '@/components/Ticket/ReservationHistory.vue'
import ReservationPolicy from '@/components/Ticket/ReservationPolicy.vue'
import ReservationInfo from '@/components/Ticket/ReservationInfo.vue'
import Season from '@/components/Ticket/Season.vue'
import SeasonIntro from '@/components/Ticket/SeasonIntro.vue'
import SeasonBenefit from '@/components/Ticket/SeasonBenefit.vue'
import SeasonInfo from '@/components/Ticket/SeasonInfo.vue'
import Skybox from '@/components/Ticket/Skybox.vue'
import SkyboxInfo from '@/components/Ticket/SkyboxInfo.vue'
import SkyboxEntrance from '@/components/Ticket/SkyboxEntrance.vue'
import SkyboxReservation from '@/components/Ticket/SkyboxReservation.vue'
import Stadium from '@/components/Ticket/Stadium.vue'
import StadiumIntro from '@/components/Ticket/StadiumIntro.vue'
import StadiumLocation from '@/components/Ticket/StadiumLocation.vue'
import StadiumTransport from '@/components/Ticket/StadiumTransport.vue'
import StadiumParking from '@/components/Ticket/StadiumParking.vue'
import StadiumFacility from '@/components/Ticket/StadiumFacility.vue'
import StadiumEntrance from '@/components/Ticket/StadiumEntrance.vue'
import StadiumGuidelines from "@/components/Ticket/StadiumGuidelines.vue"
import StadiumEmergencyGuidelines from "@/components/Ticket/StadiumEmergencyGuidelines.vue"
import Service from '@/components/Ticket/Service.vue'
import ServiceFaq from '@/components/Ticket/ServiceFaq.vue'
import ServiceNotice from '@/components/Ticket/ServiceNotice.vue'
import ServiceNoticeView from '@/components/Ticket/ServiceNoticeView.vue'
import ServicePromotion from '@/components/Ticket/ServicePromotion.vue'
import ServicePromotionView from '@/components/Ticket/ServicePromotionView.vue'
import ServiceEvent from '@/components/Ticket/ServiceEvent.vue'
import ServiceEventView from '@/components/Ticket/ServiceEventView.vue'
import GroupGuide from "@/components/Ticket/GroupGuide.vue"
import GroupGuideInfo from "@/components/Ticket/GroupGuideInfo.vue"

const ticket = [
  {
    path: '/ti',
    redirect: '/ticket'
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: Index,
    children: [
      {
        path: 'reservation',
        name: 'ticketReservation',
        component: Reservation,
        redirect: {
          name: 'ticketReservationHistory'
        },
        children: [
          // {
          //   path: 'intro',
          //   name: 'ticketReservationIntro',
          //   component: ReservationIntro,
          //   meta: {
          //     ticket: 'reservation',
          //     detail: 'intro'
          //   }
          // },
          {
            path: 'history',
            name: 'ticketReservationHistory',
            component: ReservationHistory,
            meta: {
              ticket: 'reservation',
              detail: 'history'
            }
          },
          {
            path: 'policy',
            name: 'ticketReservationPolicy',
            component: ReservationPolicy,
            meta: {
              ticket: 'reservation',
              detail: 'policy'
            }
          },
          {
            path: 'info',
            name: 'ticketReservationInfo',
            component: ReservationInfo,
            meta: {
              ticket: 'reservation',
              detail: 'info'
            }
          }
        ]
      },
      {
        path: 'season',
        name: 'ticketSeason',
        component: Season,
        redirect: {
          name: 'ticketStadiumParking'
        },
        children: [
          // {
          //   path: 'intro',
          //   name: 'ticketSeasonIntro',
          //   component: SeasonIntro,
          //   meta: {
          //     ticket: 'season',
          //     detail: 'intro'
          //   }
          // },
          // {
          //   path: 'benefit',
          //   name: 'ticketSeasonBenefit',
          //   component: SeasonBenefit,
          //   meta: {
          //     ticket: 'season',
          //     detail: 'benefit'
          //   }
          // },
          {
            path: 'info',
            name: 'ticketSeasonInfo',
            component: SeasonInfo,
            meta: {
              ticket: 'season',
              detail: 'info'
            }
          },
          {
            path: 'parking',
            name: 'ticketStadiumParking',
            component: StadiumParking,
            meta: {
              ticket: 'season',
              detail: 'parking'
            }
          },
        ]
      },
      // {
      //   path: 'teamGuide',
      //   name: 'groupGuide',
      //   component: GroupGuide,
      //   redirect: {
      //     name: 'groupGuideInfo'
      //   },
      //   children: [
      //     {
      //       path: 'intro',
      //       name: 'groupGuideInfo',
      //       component: GroupGuideInfo,
      //       meta: {
      //         ticket: 'groupGuide',
      //         detail: 'info'
      //       }
      //     },
      //   ]
      // },
      {
        path: 'skybox',
        name: 'ticketSkybox',
        component: Skybox,
        redirect: {
          name: 'ticketSkyboxInfo'
        },
        children: [
          {
            path: 'info',
            name: 'ticketSkyboxInfo',
            component: SkyboxInfo,
            meta: {
              ticket: 'skybox',
              detail: 'info'
            }
          },
          // {
          //   path: 'entrance',
          //   name: 'ticketSkyboxEntrance',
          //   component: SkyboxEntrance,
          //   meta: {
          //     ticket: 'skybox',
          //     detail: 'entrance'
          //   }
          // },
          // {
          //   path: 'reservation',
          //   name: 'ticketSkyboxReservation',
          //   component: SkyboxReservation,
          //   meta: {
          //     ticket: 'skybox',
          //     detail: 'reservation'
          //   }
          // }
        ]
      },
      {
        path: 'stadium',
        name: 'ticketStadium',
        component: Stadium,
        redirect: {
          // name: 'ticketStadiumIntro'
          name: 'ticketStadiumEmergencyGuidelines'
        },
        children: [
          // {
          //   path: 'intro',
          //   name: 'ticketStadiumIntro',
          //   component: StadiumIntro,
          //   meta: {
          //     ticket: 'stadium',
          //     detail: 'intro'
          //   }
          // },
          // {
          //   path: 'location',
          //   name: 'ticketStadiumLocation',
          //   component: StadiumLocation,
          //   meta: {
          //     ticket: 'stadium',
          //     detail: 'location'
          //   }
          // },
          // {
          //   path: 'transport',
          //   name: 'ticketStadiumTransport',
          //   component: StadiumTransport,
          //   meta: {
          //     ticket: 'stadium',
          //     detail: 'transport'
          //   }
          // },
          // {
          //   path: 'parking',
          //   name: 'ticketStadiumParking',
          //   component: StadiumParking,
          //   meta: {
          //     ticket: 'stadium',
          //     detail: 'parking'
          //   }
          // },
          // {
          //   path: 'guideline',
          //   name: 'ticketStadiumGuidelines',
          //   component: StadiumGuidelines,
          //   meta: {
          //     ticket: 'stadium',
          //     detail: 'guideline'
          //   }
          // },
          {
            path: 'emergency',
            name: 'ticketStadiumEmergencyGuidelines',
            component: StadiumEmergencyGuidelines,
            meta: {
              ticket: 'stadium',
              detail: 'emergency'
            }
          },
          // {
          //   path: 'facility',
          //   name: 'ticketStadiumFacility',
          //   component: StadiumFacility,
          //   meta: {
          //     ticket: 'stadium',
          //     detail: 'facility'
          //   }
          // },
          // {
          //   path: 'entrance',
          //   name: 'ticketStadiumEntrance',
          //   component: StadiumEntrance,
          //   meta: {
          //     ticket: 'stadium',
          //     detail: 'entrance'
          //   }
          // }
        ]
      },
      // {
      //   path: 'service',
      //   name: 'ticketService',
      //   component: Service,
      //   redirect: {
      //     name: 'ticketServiceFaq'
      //   },
      //   children: [
      //     {
      //       path: 'faq/:cate',
      //       name: 'ticketServiceFaq',
      //       component: ServiceFaq,
      //       props: true,
      //       meta: {
      //         ticket: 'service',
      //         detail: 'faq'
      //       }
      //     },
      //     {
      //       path: 'notice',
      //       name: 'ticketServiceNotice',
      //       component: ServiceNotice,
      //       meta: {
      //         ticket: 'service',
      //         detail: 'notice'
      //       }
      //     },
      //     {
      //       path: 'notice/view/:id',
      //       name: 'ticketServiceNoticeView',
      //       component: ServiceNoticeView,
      //       props: true,
      //       meta: {
      //         ticket: 'service',
      //         detail: 'notice',
      //         noSubTop: true
      //       }
      //     },
      //     {
      //       path: 'promotion',
      //       name: 'ticketServicePromotion',
      //       component: ServicePromotion,
      //       meta: {
      //         ticket: 'service',
      //         detail: 'promotion'
      //       }
      //     },
      //     {
      //       path: 'promotion/view/:id',
      //       name: 'ticketServicePromotionView',
      //       component: ServicePromotionView,
      //       props: true,
      //       meta: {
      //         ticket: 'service',
      //         detail: 'promotion',
      //         noSubTop: true
      //       }
      //     },
      //     {
      //       path: 'event',
      //       name: 'ticketServiceEvent',
      //       component: ServiceEvent,
      //       meta: {
      //         ticket: 'service',
      //         detail: 'event'
      //       }
      //     },
      //     {
      //       path: 'event/view/:id',
      //       name: 'ticketServiceEventView',
      //       component: ServiceEventView,
      //       props: true,
      //       meta: {
      //         ticket: 'service',
      //         detail: 'event',
      //         noSubTop: true
      //       }
      //     },
      //   ]
      // }
    ]
  },
]

export default ticket
