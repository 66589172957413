<template>
  <div>
    <!-- 리스트 정보 -->
    <div class="listInfo">
      <div class="sort">
        <span class="infoItem">
          <select v-model="currentMonth" class="select" @change="setMonthData(currentMonth)">
            <option value="">월선택</option>
            <option value="all">전체</option>
            <option
                v-for="(m, i) in 12"
                :key="i"
                :value="m">
              {{ m }}월
            </option>
          </select>
        </span>
        <span class="infoItem">
          <select v-model="currentYear" class="select">
            <option value="">년도선택</option>
            <option
                v-for="(y, i) in year"
                :key="i"
                :value="y.id">
              {{ y.name }}
            </option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <!-- 리스트 -->
    <div class="matchList">
      <!-- 매치 -->
      <div class="item" v-for="(data, i) in matchData" :key="i">
        <div class="info wd15">
          <div class="league" :data-league="data.game_league_logo"></div>
          <div class="time">{{data.game_time}}</div>
          <div class="date">{{data.game_date}} ({{data.yoil}})</div>
          <div class="stadium">{{data.field_name}} ({{ data.ground }})</div>
        </div>
        <div class="game emblem">
          <div class="team left">
            <img :src="data.homeLogoImg" alt="">
            <span class="name">{{data.home_team_name}}</span>
          </div>
          <div class="center">
            <span class="score ess">{{data.home_team_goal}}</span> vs <span class="score">{{data.away_team_goal}}</span>
            <div class="round"><span>{{data.id === 325 || data.id ===355 ? '몰수승' : '종료'}}</span></div>
          </div>
          <div class="team right">
            <img v-if="data.away_team!==null"  :src="data.awayLogoImg" alt="">
            <span class="name">{{data.away_team_name}}</span>
          </div>
        </div>
      </div>
      <!-- //매치 -->
    </div>
    <!-- //리스트 -->
    <div class="noData" v-if="matchData===undefined || matchData.length === 0">지난 경기 결과가 없습니다</div>
  </div>
</template>

<script>
import http from "@/api/index"
import dayjs from "dayjs"
import imgPath from "@/library/imgPath"
import common from "@/library/common"

export default {
  name: 'MatchK4Result',
  created() {
    const paramsYear = this.$store.state.params && this.$store.state.params.year
    const thisYear = this.$store.state.thisYear
    const count = thisYear - this.yearStart
    // 리스트 년도 필터 생성
    for (let i = 0; i <= count; i++) {
      const year = thisYear - i
      this.year.push({
        id: year,
        name: year
      })
    }
    this.currentYear = paramsYear || thisYear
    // this.currentMonth = dayjs().format('M')
    this.currentMonth = 'all' // 전체 디폴트 요청
  },
  watch: {
    currentYear() {
        this.init('year')
    }
    },
  data () {
    return {
      currentYear: '',
      currentMonth: '',
      yearStart: 2022, // 필터 시작 년도
      year: [],
      matchData:[],
      fixData: []
    }
  },
  methods: {
    async init (type) {
      let params = '';
      if(type === 'year'){
        params += '?year=' +this.currentYear
      }

      const match = await http.get(`/match/schedule/result/K4${params}`)
      const today = dayjs().format('YYYY.MM.DD')
      const arr = []
      match.data.data?.map((row,idx) => {
        row.meet_seq === 300 ?
            row.game_league_logo = 'k4' :
            row.game_league_logo = 'k3'
        row.field_name = row.field_name.replace(/\s/g, "")
        row.game_time = row.game_time.substring(0, 5)
        row.game_date = dayjs(row.game_date).format("YYYY.MM.DD")
        row.month = dayjs(row.game_date).format('M')
        if (row.home_team_name === '전북') {
          row.home_team_name = '전북현대B'
        }
        row.home_team_name.includes('전북') ? row.ground = 'HOME' : row.ground = 'AWAY'
        if(row.away_team_name.includes('전북')){
          row.away_team_name = row.home_team_name
          row.away_team = row.home_team
          row.set_away_team_goal = row.away_team_goal
          row.away_team_goal =row.home_team_goal
          row.home_team_name = '전북현대B'
          row.home_team = 'K05'
          row.home_team_goal = row.set_away_team_goal
        } else {
          row.home_team_name = '전북현대B'
        }
        row.homeLogoImg = imgPath.setTeamLogo(row.home_team)
        row.awayLogoImg = imgPath.setTeamLogo(row.away_team)
        if(!dayjs(row.game_date).isSameOrAfter(today)){
          arr.push(match.data.data[idx])
        }
      })
      this.fixData = arr
      this.setMonthData(this.currentMonth)
    },
    setMonthData (currentMonth) {
      if (currentMonth === '') {
        return this.fixData
      }
      if (currentMonth === 'all') {
        return this.matchData = common.sortGamesByDate(this.fixData)
      } else {
        let arr = this.fixData
        let newArr = arr.filter(item => item.month === String(currentMonth))
        this.matchData = common.sortGamesByDate(newArr)
      }
    }
  }
}
</script>
