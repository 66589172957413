<template>
  <div>
    <div class="sectionTitle line">시즌티켓 주차증 구매</div>
    <div class="seasonInfoCard">
      <div class="title">
        JEONBUK HYUNDAI
        <p>2025 <span class="mobileBreak">P2 PARKING TICKET</span><span class="mobileBreak"></span></p>
      </div>
      <div class="close">판매마감<br><strong>2025.01.24(금) 17:00</strong></div>
      <Countdown
          v-if="isOnTime"
          :endDate="datetime"
          :center="true"
          :label="true"
          :labelColor="'rgba(255,255,255,0.7)'"/>
      <div class="btns">
        <button type="button" class="btn yellow large" @click="purchase" :disabled="!isOnTime" v-if="isOnTime">구매하기</button>
      </div>
    </div>
    <!-- 시즌 준비중 -->
    <div class="indexBox waitingSeason" style="margin-top: 30px;">
      <img :src="img" alt="주차장 안내"/>
<!--      <img src="@/assets/img/bg_waitingSeason.png" alt="준비중입니다"/>-->
    </div>
    <!-- //시즌 준비중 -->
  </div>
</template>

<script>
import Countdown from '@/components/Util/Countdown.vue'
import dayjs from "dayjs"
import { reservePopup } from '@/library/ticketlink'
import router from '@/router'
import imgPath from "@/library/imgPath";
export default {
  name: 'TicketStadiumParking',
  components: {
    Countdown
  },
  computed: {
    isOnTime() {
      const today = dayjs().toISOString();
      return dayjs(today).isBefore(this.datetime);
    }
  },
  created () {
    this.getEncryptedId();
    this.isOnTime = this.checkIsOnTime(); // 즉시 호출하여 초기값 설정
    setInterval(() => {
      this.isOnTime = this.checkIsOnTime();
    }, 500);
    const year = dayjs().year()
    this.img = `${imgPath.imgPath.jbhd_s3}uploadFile/ticket/${year}season_parking.jpeg`
  },
  mounted() {
  },
  data () {
    return {
      state: false, // 티켓 임시페이지 사용시 false
      datetime: '2025-01-24T08:00:00.000Z',
      encrypted_id: '',
      isOnTime: this.checkIsOnTime(),
      img: ''
    }
  },
  methods : {
    getEncryptedId() {
      if(this.$store.state.user.encrypted_id !== null) {
        this.encrypted_id = this.$store.state.user.encrypted_id
      } else {
        this.encrypted_id = ''
      }
    },
    checkIsOnTime() {
      const today = dayjs().toISOString();
      return dayjs(today).isBefore(this.datetime);
    },
    purchase() {
      this.getEncryptedId()
      if(this.encrypted_id !== '') {
        const url = reservePopup(this.encrypted_id, "pc");
        window.gtag('event','btn_구매하기',{'event_category':'시즌티켓 주차권', 'event_label':this.$store.state.user.id})
        window.open(url,'tl', 'width=1010, height=600, left=100, top=100, scrollbars=yes')
      } else {
        alert("통합회원 로그인 후 이용해주세요.")
        const redirectUrl = router.currentRoute.path;
        router.push({name: 'signin', query: {redirectUrl: redirectUrl}});
      }
    }
  },
}
</script>
