<template>
  <div class="introSection section7" :style="{height: `${sectionHeight}px`}">
    <div class="sectionTitle section7Title">비경기 관련 수상</div>
    <div class="img section7Img"><div class="dimm"></div><img :src="require('@/assets/img/history/bg_club_award.png')" alt=""></div>
    <ul class="awardList">
      <li>2006 K리그 공로상</li>
      <li>2014 K리그 팬 프렌들리 클럽상</li>
      <li>2015 K리그 팬 프렌들리 클럽상</li>
      <li>2015 K리그 풀 스타디움상</li>
      <li>2015 K리그 플러스 스타디움상</li>
      <li>2018 K리그 풀 스타디움상</li>
      <li>2023 플러스 스타디움상</li>
      <li>2023 스포츠산업대상 장관상</li>
      <li>2024 스포츠이벤트 안전경영 시스템 인증</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HistoryClubAward',
  props: [
    'active',
    'height'
  ],
  computed: {
    isActive () {
      return this.active && this.active.index === 7
    },
    sectionHeight () {
      const height = this.height && this.height.minHeight ? parseInt(this.height.minHeight.replace(/[^0-9]/g, '')) : 3000
      return height * 3
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  },
  mounted () {
    document.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onScroll()
    this.onResize()
  },
  data () {
    return {
      parallaxWidth: false
    }
  },
  methods: {
    onScroll () {
      const isActive = this.isActive
      const section = document.querySelector('.section7')
      const scrollY = window.scrollY
      if (section) {
        const sectionTitle = section.querySelector('.section7Title')
        const img = section.querySelector('.section7Img')
        const imgObj = img.querySelector('img')
        const award = section.querySelector('.awardList')
        const scrollDiff = section.offsetTop - scrollY
        const scrollPercent = parseInt(this.active && this.active.scrollPercent) >= 33.33 ? 100 : parseInt(this.active && this.active.scrollPercent) * 3
        const pos = scrollDiff > 0 ? 0 : scrollDiff * -1
        const imgOffset = img.offsetTop
        const awardPos = award.getBoundingClientRect().top
        if (isActive && section && this.parallaxWidth) {
          // img position + img
          if (pos >= imgOffset) {
            img.style.transform = `translateY(${pos - imgOffset}px)`
            img.style.width = `${62.5 + ((37.5) * scrollPercent / 100)}%`
            imgObj.style.opacity = scrollPercent > 50 ? 0.5 : 1 - (scrollPercent / 100)
          } else {
            img.style.transform = ''
            img.style.width = ''
            imgObj.style.opacity = ''
          }
          // title
          if (awardPos > sectionTitle.offsetTop * 1.5) {
            sectionTitle.style.transform = `translateY(${pos}px)`
          }
        } else {
          sectionTitle.style.transform = ''
          sectionTitle.style.marginTop = ''
          img.style.transform = ''
          img.style.width = ''
          imgObj.style.opacity = ''
        }
      }
    },
    onResize () {
      // resize
      this.parallaxWidth = window.innerWidth >= 1600
    }
  }
}
</script>
