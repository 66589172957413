<template>
  <div>
    <div v-if="state">
      <div class="headCoach" v-if="state">
        <div class="info">
          <div class="img"><img src="@/assets/img/player/headcoach.png" alt=""></div>
          <div class="text">
            <div class="container">
              <div class="position">HEAD COACH</div>
              <div class="name">GUSTAVO AUGUSTO<br>POYET DOMÍNGUEZ</div>
            </div>
          </div>
        </div>

      </div>
      <div class="headCoach">
        <div class="container">
          <div class="intro">
            <div class="introTitle">감독 소개</div>
            <div class="flexBox">
              <div class="flexInner"><img src="@/assets/img/player/headcoach_intro.png" alt=""></div>
              <div class="flexInner" style="text-align: justify;">전북현대는 2024년 12월 24일 팀의 재도약과 새 시대를 함께 할 파트너로 전 그리스 대표
                팀 감독 ‘거스 포옛(Gustavo Augusto Poyet Domínguez/57세/우루과이-스페인 이중국적)을 최종 낙점했다.<br><br>
                전북현대는 유럽에서 선수뿐만 아니라 지도자로서 다양하고 풍부한 경험, 세계 정상급의 선 수들과의 커뮤니케이션 능력이 뛰어난 ’거스 포옛‘
                감독을 제9대 감독으로서 최고의 적임자 로 판단했다. <br><br>
                거스 포옛 감독은 잉글랜드 프리미어리그 첼시FC와 토트넘에서 선수로 활동했으며 이후 리즈 유나이티드(수석코치)와
                토트넘 핫스퍼(수석코치)에서 코치로 지도 경력을 쌓았다. <br><br>
                이후 브라이튼 앤 호브 알비온(잉글랜드-EFL 챔피언십)에서 감독직을 시작한 거스 포옛은 선덜랜드(잉글랜드-EPL) 등
                잉글랜드를 비롯해 AEK 아테네(그리스-슈퍼리그), 레알 베티스(스페인-라리가), 보르도(프랑스-리그1) 등
                다양한 리그와 클럽에서 경험을 쌓았으며 최근에는 그리스 국가대표팀(’22~24)에서 감독으로 활약했다.<br><br>
                우루과이 출신인 거스 포옛은 선수 시절 타 리그에서 언어와 문화 차이를 스스로 극복하고 최고의 선수로 발돋움했으며
                지도자로서도 수많은 도전과 시련을 겪으며 한 단계 한 단계 성장한 축구인이자 지도자로 평가받는다. <br><br>
                전북현대는 거스 포옛 감독이 세계 최고의 축구 무대인 잉글랜드 프리미어리그에서 지도자 로서 성공과 실패를 통해 쌓은
                역경의 경험과 노하우가 팀을 다시 일으켜 세울 수 있는 큰 원동력이 될 것이라고 내다봤다.<br><br>
                특히 축구에 대한 진정성을 보이며 선수들과 소통하고 포용하는 능력은 리그 최정상급 수준의 선수들로
                구성된 전북현대 선수단을 건강하게 매니지먼트 할 수 있는 최고의 장점으로 평가했다.<br><br>
                또한 거스 포옛 감독이 추구하는 유연한 전술과 뛰어난 통찰력에도 큰 기대를 모으고 있다.<br><br>
                거스 포옛을 보좌할 코치진은 2009년 ’브라이튼 앤 호브 알비온‘에서부터
                15년간 함께 한 수석코치 마우리시오(MAURICIO TARICCO/51세)를 비롯해 파나요티스(VOULGARIS PANAGIOTIS/39세) 피지컬 코치 , 디에고(DIEGO POYET/29세) 분석코치 등
                기존 사단 이 선보였던 최고의 팀워크를 한국에서도 이어간다.<br><br>
                구단은 거스 포옛의 국내 무대 경험에 대한 약점을 보완하고 선수단과의 원활한 가교역할을 위해 정조국 코치(전 제주유나이티드)와
                황희훈 GK코치(KFA 전임지도자)를 선임해 국내·외 코치 간의 시너지 효과를 낼 것이라고 전했다.<br><br>
                거스 포옛 감독은 “아시아와 K리그 무대는 감독으로서 새로운 도전이다. 이 도전이 성공할 수 있도록 선수들과 그리고 팬들과 함께 만들어가겠다“며
                ”축구는 소통과 신뢰가 전술, 전략보다 앞설 수 있다고 생각한다. 선수들과 소통하고 팬들에게 신뢰받아 전북현대가 K리그 최고의 팀으로 다시 거듭날 수 있도록 최선을 다하겠다“고 포부를 전했다.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="headCoach">
        <div class="container">
          <div class="intro">
            <img src="@/assets/img/bg_waitingSeason.png" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'

export default {
  name: 'headCoach',
  created() {
    // this.init()
  },
  data(){
    return {
      state: true,
    }
  },
  methods:{
    async init() {
      const data = await http.get('/player/staff/headcoach')
    }
  }
}
</script>
