<template>
  <div class="container">
    <!-- 리스트 정보 -->
    <div class="listInfo" v-if="state && playerList && playerList.length">
      <div class="sort" v-if="playerList[0].position !== 'staff'">
        <span class="infoItem">
          <select v-model="sort" class="select">
            <option value="playerNo">번호순</option>
            <option value="name">이름순</option>
          </select>
        </span>
      </div>
    </div>
    <!-- //리스트 정보 -->
    <div v-if="state && playerList && playerList.length">
      <div
        v-for="(group, index) in playerList"
        :key="index" class="teamGroup">
        <div class="posTitle">{{ positionName(group.position) }}</div>
        <div class="teamList teamList2">
          <div
            v-for="(item, index) in group.list"
            :key="index"
            class="teamItem">
            <div class="img">
              <img v-if="item.profile" :src="playerImg('U18', item.profile)" :alt="item.name">
              <img v-else :src="staffImg(item.img)" :alt="item.kor_name">
            </div>
            <div class="info">
              <div class="num" v-if="group.position !== 'staff'">
                <img v-for="(num, idx) in imgToNum(item.playerNo, 'number_primary',)" :key="idx" :src="num" class="imgNum" />
              </div>
              <div class="profile" v-if="group.position !== 'staff'">
                <div class="name">{{ item.name }}</div>
                <div class="physical" v-if="item.height !== 0">{{item.height}}cm / {{item.weight}}kg</div>
              </div>
              <div class="profile" v-else>
                <div class="name">{{ item.kor_name }}</div>
                <div class="physical">{{item.role}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="waitingSeason"><img src="@/assets/img/bg_waiting_U.png" /> </div>
  </div>
</template>

<script>
import http from "@/api/index"
import regExp from '@/common/regExp'
import imgPath from "@/library/imgPath"

export default {
  name: 'teamU18',
  props: [
    'position'
  ],
  computed: {
    playerList () {
      const position = this.position
      const sort = this.sort
      const filteredList = this.list.filter(x => position === 'all' ? x : x.position.toLowerCase() === position)
      function sortBy (list, sort) {
        return list.map(x => {
          return {
            ...x,
            list: x.list.sort((a, b) => {
              return a[sort] > b[sort] ? 1 : a[sort] < b[sort] ? -1 : 0
            })
          }
        })
      }
      return sortBy(filteredList, sort)
    }
  },
  watch: {
    position (position) {
      // 전체이면 숫자순
      if (position === 'all') {
        this.sort = 'uniform_number'
      }
    }
  },
  created () {
    this.init()
  },
  data () {
    return {
      state: false, //fix 2025 season
      sort: 'playerNo',
      list: [],
      pos: {
        'GK': 'GOALKEEPER',
        'DF': 'DEFENDER',
        'MF': 'MIDFIELDER',
        'FW': 'FORWARD',
        'STAFF': 'STAFF'
      }
    }
  },
  methods: {
    positionName (pos) { // GK -> GOALKEEPER
      pos = pos.toUpperCase()
      return this.pos[pos]
    },
    comma (num) {
      return regExp.comma(regExp.numberOnly(num))
    },
    imgToNum (num, path) { // 등번호 등 숫자를 하나씩 쪼개 이미지로 리턴
      const nums = `${this.comma(num)}`.split('')
      function getImg(n) {
        return require(`@/assets/img/${path || 'number'}/${n === ',' ? 'comma' : n}.png`)
      }
      return nums.map(x => getImg(x))
    },
    playerImg (position, num) {
      try {
        return `${imgPath.setUPlayer(position)}/${num}.png`
      } catch (e) {
        return null
      }
    },
    staffImg (id) {
      try {
        return `${imgPath.setStaff(id)}.png`
      } catch (e) {
        return null
      }
    },
    async init () {
      const proTeam = await http.get('/player/youth/list/U18')
      const list = proTeam && proTeam.data && proTeam.data.body ? proTeam.data.body : null
      if (list) {
        Object.keys(list).forEach((key) => {
          this.list = this.list.concat({position: key, list: list[key]}) // list[key] = 포지션별 선수 리스트(array)
        })
      }
    },
    detailPlayer (num) {
      this.$router.push({
        name: 'playerDetail',
        params: {
          num: num
        }
      })
    }
  }
}
</script>
